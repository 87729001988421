<template>
  <div class="f1" id="全球业务">全球业务 + 制造</div>
  <div class="image"><img :src="require('@/assets/about/range.png')" /></div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .image {
    @if ($searchWidth<1100) {
      margin: 1.5rem 0.5rem 3.2rem;
    } @else {
      max-width: 11.47rem;
      margin: 0.39rem auto 0.59rem;
    }

    img {
      width: 100%;
      display: block;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
