<template>
  <div class="container">
    <div class="f1">生产实力</div>
    <div class="main">
      <div class="images">
        <div v-for="(item, index) in images" :key="index">
          <img :src="item" />
        </div>
        <!--      <img :src="require('@/assets/about/produce/2.png')" />-->
        <!--      <img :src="require('@/assets/about/produce/3.png')" />-->
      </div>
      <div class="text">
        <div class="text-item" v-for="(item, index) in list" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="s">
            <div class="s-item" v-for="(sItem, sIndex) in item.s" :key="sIndex">
              <div class="s-icon">
                <img :src="require('@/assets/gou.png')" />
              </div>
              <div class="s-t">
                <div class="s-name">{{ sItem.name }}</div>
                <div class="s-info" v-html="sItem.info"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        require("@/assets/about/produce/1.png"),
        require("@/assets/about/produce/2.png"),
        require("@/assets/about/produce/3.png"),
      ],
      list: [
        {
          title: "复合产线",
          s: [
            { name: "标签", info: "高品质印刷吊牌" },
            { name: "RFID卷装标签", info: "NFH，NFC，&EAS" },
            { name: "PFL标签", info: "NFH，NFC，&EAS<br/>（RF primarily）" },
            { name: "热转印标签", info: "NFH，NFC" },
          ],
        },
        {
          title: "高速印刷/赋码",
          s: [
            { name: "生产基地", info: "中国、孟加拉、越南、洛杉矶、土耳其" },
            {
              name: "优势/亮点",
              info: "高速PEV机器、反应迅速、全检所有进货原材料",
            },
            { name: "产能", info: "450K标签/周/台" },
            { name: "100%质量控制", info: "EPC到UPC验证" },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background-size: cover;
    background-image: url("~@/assets/about/produce/bg.png");
    background-repeat: no-repeat;

    @if ($searchWidth<1100) {
      padding: 0 0 3.2rem 0;
    } @else {
      padding: 0 0 0.6rem 0;
    }
  }

  .main {
    background: linear-gradient(180deg, #fcffff 0%, #ffffff 100%);

    display: flex;

    @if ($searchWidth<1100) {
      margin: 0.55rem 0.5rem 0;
      flex-direction: column;
      box-shadow: 0px 0.2rem 0.55rem.15rem rgba(83, 177, 165, 0.23);
      border-radius: 0.4rem;
      padding: 0.7rem 0.65rem 1.25rem;
    } @else {
      max-width: 13.2rem;
      box-sizing: border-box;
      margin: 0.42rem auto 0;
      box-shadow: 0px 0.04rem 0.11rem 0.03rem rgba(83, 177, 165, 0.23);
      padding: 0.41rem;
    }
  }

  .images {
    display: grid;

    @if ($searchWidth<1100) {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 0.25rem;
    } @else {
      grid-template-columns: repeat(3, 2.33rem);
      grid-column-gap: 0.2rem;
      margin-right: 0.51rem;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  .text-item {
    &:not(:first-child) {
      margin-top: 0.3rem;
    }

    .title {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;
      margin-bottom: 0.1rem;

      @if ($searchWidth<1100) {
        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      } @else {
        font-size: 0.3rem;
        line-height: 0.42rem;
      }
    }

    .s {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      @if ($searchWidth<1100) {
        grid-row-gap: 0.5rem;
        grid-column-gap: 1.1rem;
      } @else {
        grid-row-gap: 0.2rem;
        grid-column-gap: 0.4rem;
      }
    }

    .s-item {
      display: flex;
    }

    .s-icon {
      flex-shrink: 0;

      @if ($searchWidth<1100) {
        width: 0.75rem;
        margin-right: 0.5rem;
        margin-top: 0.25rem;
      } @else {
        width: 0.2rem;
        margin-right: 0.09rem;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    .s-name {
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #333333;

      @if ($searchWidth<1100) {
        font-size: 0.8rem;
        line-height: 1.25rem;
      } @else {
        line-height: 0.25rem;
        font-size: 0.2rem;
      }
    }

    .s-info {
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #7a7a7a;
      margin-top: 0.05rem;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        line-height: 1rem;
      } @else {
        font-size: 0.16rem;
        line-height: 0.25rem;
      }
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
