<template>
  <div class="container">
    <div class="main">
      <div class="text">
        <div class="t1">公司简介</div>
        <div class="t2">
          常达智能物联（深圳）有限公司（下称“常达智能物联”）为香港上市公司常达控股的全资子公司，深圳市物联网产业协会-创始理事单位。<br />
          常达智能物联专注于RFID核心技术，拥有自主研发团队，开发RFID系统平台及RFID产品，为客户提供一站式RFID解决方案和配套软硬件的开发与实施服务。目前，常达智能物联重点聚焦智能包装、固定资产管理、智能服装门店三大软件系统业务。同时发展防伪溯源、工器具管理、档案管理、智能洗涤、智能仓储等物联网垂直领域应用。工厂可批量生产RFID洗水唛、RFID织唛标签、RFID洗涤标签、RFID吊牌、智能包装盒等产品。
        </div>
      </div>
      <div class="image moblie">
        <img :src="require('@/assets/about/m-info-bg.png')" />
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;

    @if ($searchWidth<1100) {
    } @else {
      background-image: url("~@/assets/about/info-bg.png");
    }
  }

  .main {
    margin: auto;
    box-sizing: border-box;
    min-height: 6.2rem;

    @if ($searchWidth<1100) {
      padding: 3.2rem 0.8rem;
    } @else {
      max-width: 13.2rem;
      padding: 1.07rem 0;
    }
  }

  .text {
    @if ($searchWidth<1100) {
    } @else {
      width: 50%;
    }
  }

  .t1 {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #5ec7b9;

    @if ($searchWidth<1100) {
      font-size: 1rem;
      margin: 0 auto 0.8rem;
    } @else {
      line-height: 0.34rem;
      font-size: 0.38rem;
      max-width: 5.17rem;
      margin: 0 auto 0.3rem;
    }
  }

  .t2 {
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #7a7a7a;
    // max-width: 5.17rem;
    margin: auto;

    @if ($searchWidth<1100) {
      line-height: 1.25rem;
      font-size: 0.7rem;
    } @else {
      line-height: 0.34rem;
      font-size: 0.18rem;
      max-width: 5.17rem;
    }
  }
  .image {
    width: 100%;
    margin-top: 1rem;
    img {
      width: 100%;
      display: block;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
